.div {
  position: relative;
}

#github {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  #github {
    display: none;
  }
}

.App > #tools {
  position: absolute;
  bottom: 20px;
  right: 24px;
  display: flex;
}

.App .tool {
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin: 5px;
  color: black;
}

.App > #tools .tool:hover {
  color: #737373;
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 12px;
  height: 12px;
  bottom: 0;
  right: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
  background-color: white;
}

.modal {
  position: absolute;
}

::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: white; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #e0e0e0; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid white; /* creates padding around scroll thumb */
}
