#files .file,
.examples .file,
.modal.file-options .file {
  background-color: #e0e0e0;
  margin: 5px;
  padding: 2px 5px 2px 5px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  max-width: 250px;
}

.examples {
  position: absolute;
  bottom: 22px;
  right: 100px;
  display: flex;
  align-items: center;
}

.modal.file-options {
  bottom: 55px;
  left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  padding: 5px 10px;
  border-radius: 4px;
}

.modal.file-options > div {
  display: flex;
  align-items: center;
}

.modal.file-options .file {
  margin-left: 0px;
  display: inline-block;
}

.modal.file-options .file.current {
  background-color: #ffffff;
}

#files .tool:hover {
  fill: #737373;
}

#files .file.current {
  background-color: #ffffff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#files .file:hover,
.examples .file:hover,
.modal.file-options .file:hover {
  background-color: white;
  border-color: white;
}

.App > #files {
  position: absolute;
  bottom: 20px;
  left: 24px;
  display: flex;
  max-width: 50vw;
  flex-wrap: wrap;
  min-width: min(300px, 50vw);
  z-index: 1000;
}

.modal.file-options .tool {
  height: 15px;
  width: 15px;
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
}
